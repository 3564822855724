<template>
	<div class="detail-wrap">
		<div class="help-detail">
			<div class="title">龙皇资质</div>
			<div class="content">
                <div>备案号：浙ICP备2021016719号-1 </div>
                <div>增值电信许可证号：浙B2-20210657</div>
                <img src="https://shop.zjlonghuang.com/upload/common/images/20240528/20240528051626171688778609077.png" alt="">
            </div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'qualifications',
	components: {},
	data: () => {
		return {
			detail: [],
			loading: true
		};
	},
	created() {
	},
	methods: {
	}
};
</script>
<style lang="scss" scoped>
.help-detail {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
	.title {
		text-align: center;
		font-size: 18px;
		margin: 10px 0;
	}
	.info {
		margin: 0 43px;
		border-bottom: 1px dotted #e9e9e9;
		.time {
			text-align: center;
			color: #838383;
			margin-bottom: 17px;
		}
	}
	.content {
		padding-top: 10px;
		margin: 0 65px;
	}
}
.path {
	padding: 15px 0;
}
</style>
